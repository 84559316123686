import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <header>
        <h3>Heikki Lotvonen is a coder & designer from Finland specialized in modular design and text art. He's the creator and developer of <a href="https://glyphdrawing.club" target="_blank" rel="noopener noreferrer">glyphdrawing.club</a>, a fun new tool for modular design.</h3>
        <h3>
            write me at <a href="mailto:hlotvonen@gmail.com" target="_blank" rel="noopener noreferrer">hlotvonen@gmail.com</a> — visit <a href="https://grmmxi.fi" target="_blank" rel="noopener noreferrer">grmmxi.fi</a> for more — check out my <a href="https://instagram.com/heikkiveikko" target="_blank" rel="noopener noreferrer">instagram</a> — and read my <a href="https://blog.glyphdrawing.club/" target="_blank" rel="noopener noreferrer">modular design blog</a>
        </h3>
      </header>
    );
  }
}

export default Footer;
