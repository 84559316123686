import React from 'react'
import PropTypes from 'prop-types'
import Header from '../components/Header'
import Footer from '../components/Footer'
import backgroundImage from '../../static/images/heikkilotvonen-design-background.jpg'
import './layout.css'

const Layout = ({ children }) => (
  <div className="container">
    <div className="wrapper">
      <img
        src={backgroundImage}
        className="bg"
        alt=""
      />
      <div className="screencontainer">
          <div className="main">
          <Header />
          <Footer />
          {children}
          </div>
      </div>
    </div>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
